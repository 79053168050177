import {Injectable} from '@angular/core';
import {forkJoin, from, Observable, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {GameService} from './game.service';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;
  user: User;

  loading = false;
  errorMessage;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router,
              private gameService: GameService) {
    // Subscribe to current auth user data from database (updates when auth changes)
    this.user$ = this.afAuth.authState.pipe(
      switchMap(authState => {
        if (authState) {
          return from(this.afs.doc<User>(`users/${authState.email}`).valueChanges());
        } else {
          return of<User>(null);
        }
      }));

    this.user$.subscribe(user => this.user = user);
  }

  ///// Login/Signup //////

  signIn(email: string, password: string): void {
    this.errorMessage = null;
    this.loading = true;
    if (!email) {
      this.loading = false;
      this.errorMessage = 'Please enter email address!';
    } else if (!password) {
      this.loading = false;
      this.errorMessage = 'Please enter password';
    } else {
      this.afAuth.signInWithEmailAndPassword(email, password).catch((error) => {
        this.loading = false;
        this.errorMessage = error.message;
      }).then((credential) => {
        // Login successful
        // @ts-ignore
        // this.makeUserTwilightAdmin(credential.user);
        // @ts-ignore
        this.router.navigate(['/']).then(() => {
          this.loading = false;
        });
      });
    }
  }

  logout(): void {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/sign-in']);
    }).catch((error) => {
      alert(error.message);
    });
  }

  currentUserIsAdminForGame(): Observable<boolean> {
    return forkJoin([
      this.gameService.currentGame$.pipe(take(1)), this.user$.pipe(take(1)) // Join both queries
    ]).pipe(
      map(res => { // res contains array with game data at 0 and user data at 1
        return !!(res[1] && res[1].roles.admin?.includes(res[0].id)); // if user is admin for current game
      }),
      tap(isAdmin => {
        if (!isAdmin) {
          this.errorMessage = 'You are not authorized to access this site.'; // Set error message and logout if unauthorised
          this.logout();
        }
      })
    );
  }

  // Never use this, just an example
  private makeUserTwilightAdmin(user): Promise<any> {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.email}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      roles: {
        admin: [
          'twilight2021'
        ]
      }
    };
    return userRef.update(data);
  }
}
