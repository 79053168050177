<div class="bg-danger py-5 text-white text-center">
  <h1>Catching Teams</h1>
</div>
<div class="bg-light py-2">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search teams..." aria-label="Recipient's username">
          <button class="btn btn-outline-dark" type="button"><i class="fas fa-search"></i></button>
        </div>
      </div>
      <button class="btn btn-outline-dark"><i class="fas fa-plus me-2"></i>Create Team</button>
    </div>
  </div>
</div>
