<div class="bg-success py-5 text-white text-center">
  <h1>Check-in</h1>
</div>

<div class="bg-light py-2">
  <div class="container">
    <ul class="nav nav-tabs registration-tabs">
      <li class="nav-item">
        <button class="nav-link" [class.active]="!scanMethod" (click)="scanMethod = false; handle(action, 'stop');">Search</button>
      </li>
      <li class="nav-item">
        <button class="nav-link" [class.active]="scanMethod" aria-current="page" (click)="scanMethod = true;  handle(action, 'start');">Scan
        </button>
      </li>
    </ul>

    <div *ngIf="scanMethod" class="tab-content mx-3 my-4">
      <!--      <div class="row">-->
      <!--        <div class="col" *ngIf="!(scannerService.scanner$ | async)">-->
      <!--          <h5>Register Teams Using the App</h5>-->
      <!--          <p>Enter the code shown in the scanning companion app.</p>-->
      <!--          <div class="input-group my-4">-->
      <!--            <input #scanCode type="text" class="form-control" placeholder="Code (e.g. 1234)"-->
      <!--                   (keyup.enter)="scannerService.setScannerId(scanCode.value)">-->
      <!--            <button class="btn btn-primary" type="button" (keyup.enter)="scannerService.setScannerId(scanCode.value)">-->
      <!--              Connect-->
      <!--            </button>-->
      <!--          </div>-->
      <!--          <p *ngIf="scannerService.errorMessage" class="text-danger"><i-->
      <!--            class="fas fa-exclamation-circle me-2"></i>{{scannerService.errorMessage}}</p>-->
      <!--        </div>-->
      <!--        <div class="col" *ngIf="(scannerService.scanner$ | async) as scanner">-->
      <!--          <h5>Connected to Scanner {{scanner.id}}</h5>-->
      <!--          <p>This scanner has just scanned {{scanner.value}}</p>-->
      <!--          <button class="btn btn-primary" type="button" (click)="scannerService.disconnect()">Disconnect</button>-->
      <!--        </div>-->
      <!--        <div class="col" #spacer></div>-->
      <!--        <div class="col text-center px-4">-->
      <!--          <p class="lead">Widegame Scan Companion</p>-->
      <!--          <p class="scan-logo"><i class="fas fa-qrcode"></i></p>-->
      <!--          <p>Go to <a class="link-primary" href="https://scan.widegame.app" target="_blank">scan.widegame.app</a> on-->
      <!--            your phone to-->
      <!--            register teams using their cards.</p>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row">
        <div class="col-8">
          <h5>Scan a team's code</h5>
          <p>Scan the teams code using your camera</p>
        </div>
         <div class="col-4">
        <div *ngIf="action.isLoading" class="scan-loader">
          <app-loader></app-loader>
        </div>

        <ngx-scanner-qrcode class="scanner" [config]="config" #action="scanner"
                            (event)="scanSuccess(action.data.value[0]?.value)"></ngx-scanner-qrcode>

      </div>
      </div>
    </div>

    <div *ngIf="!scanMethod" class="tab-content mx-3 my-4">
      <div class="row">
        <div class="col">
          <h5>Search for teams to register</h5>
          <p>The list below only shows teams with the status 'Unregistered'.</p>
          <div class="input-group my-4">
            <input #search (input)="updateSearch($event.target.value)" pattern="[A-Za-z0-9]"
                   type="search" class="form-control" placeholder="Search teams..."
                   aria-label="Recipient's username">
          </div>
          <p *ngIf="scannerService.errorMessage" class="text-danger"><i
            class="fas fa-exclamation-circle me-2"></i>{{scannerService.errorMessage}}</p>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="container mt-5">
  <div *ngIf="searchResults$ | async as searchResults">

    <div class="mb-5 shadow" *ngFor="let runner of searchResults">
      <div class="p-2 bg-light border-0">
        <div class="card-body">
          <div class="row">

            <div class="col-md-5 border-end">
              <span class="h4" [innerHTML]="runner.name | highlightSearch: (searchTerm$ | async)"></span>
              <span class="h4 fw-normal"
                    [innerHTML]="' (' + (runner.teamNumber | number:'3.0' | highlightSearch: (searchTerm$ | async)) + ')'"></span>
              <h5 class="fw-normal mt-3">Group: <span class="fw-bold"
                                                      [innerHTML]="runner.group | highlightSearch: (searchTerm$ | async)"></span>
              </h5>
              <h5 class="fw-normal mt-3">Category: <span class="fw-bold">{{runner.category}}</span></h5>
              <h5 class="fw-normal mt-3">Team Leader: <b>{{runner.members[0]?.name ?? 'Not set!'}}</b></h5>
              <h5 class="fw-normal mt-3">Team size: <b>{{runner.members.length}} members</b> (inc. leader)</h5>
            </div>

            <div class="col-md-7 ps-4">
              <h4>Catches</h4>
              <div *ngIf="runner.catches?.length > 0 else noCatches">
                <p><b>This team has been caught {{getValidCatches(runner.catches)}} times</b>. Earliest first:</p>
                <div *ngFor="let c of runner.catches"
                     class="shadow {{c.discrepancy ? 'bg-white' : 'bg-white'}}  mb-2 d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-between align-items-stretch">
                    <div class="bg-primary text-white text-center d-flex align-items-center px-2">
                      {{c.time | date: 'HH:mm'}}
                    </div>
                    <div class="flex-fill py-1 px-3 pe-0" [ngSwitch]="c.status">
                      <b *ngSwitchCase="'Valid'"><i
                        class="fa-regular fa-circle-check text-success me-3"></i>{{c.catchingTeamName}} <span
                        class=fw-normal>{{c.catchNumber === 1 ? '🥇' : c.catchNumber === 2 ? '🥈' : c.catchNumber === 3 ? '🥉' : '(' + c.catchNumber + ')'}}</span>
                      </b>
                      <b *ngSwitchCase="'Invalid'"><i
                        class="fa-solid fa-triangle-exclamation text-warning me-3"></i>{{c.catchingTeamName}} <span
                        class="fw-normal">({{c.reason}})</span></b>
                    </div>
                    <span *ngIf="c.discrepancy">({{c.discrepancy}})</span>
                  </div>
                </div>
              </div>
              <ng-template #noCatches>
                <h5 class="text-center mt-5 fw-normal">This team has not been caught!</h5>
              </ng-template>
            </div>

          </div>
        </div>
      </div>
      <div class="bg-white border-0 text-end p-2">
        <button class="btn btn-light me-2" routerLink="/runners/{{runner.id}}">Edit</button>
        <button class="btn btn-success text-white" (click)="runnerService.checkIn(runner.id)">Check-in</button>
      </div>

    </div>

    <h6 *ngIf="(searchResults$ | async)?.length < 1 && !runnerService.loading" class="text-center">All teams with this
      search criteria are checked in</h6>

  </div>


  <app-loader *ngIf="runnerService.loading"></app-loader>
</div>

<!--<div *ngIf="runnerService.scannedTeam$">{{(runnerService.scannedTeam$ | async)?.id}}</div>-->

<!--<p *ngIf="runnerService.scannedTeam$ | async as team">{{team.name}} {{team.id}}</p>-->
