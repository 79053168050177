<h3>Game settings</h3>
<p>Manage and add games & set the current game. See the games below.</p>

<div *ngFor="let team of (game.games$ | async)" class="d-flex justify-content-between align-items-center border-bottom border-light border-1 py-2">
  <div>
    <h6 class="mb-1">{{team.name}}</h6>
    <p class="mb-0">ID: {{team.id}}</p>
  </div>
  <button (click)="game.setCurrentGame(team.id, true)" *ngIf="game.currentGame.id != team.id" class="btn btn-sm btn-outline-primary">Make current game</button>
  <p *ngIf="game.currentGame.id == team.id" class="mb-0 text-success fw-bold"><i class="fa-solid fa-check me-2"></i>Already the current game</p>
</div>


<button (click)="showCreateDialog = true" *ngIf="!showCreateDialog" class="btn btn-primary my-4"><i class="fas fa-plus me-2"></i>Create new game</button>

<div *ngIf="showCreateDialog">
  <form>
    <div class="card p-2 bg-light border-0 my-4">
      <div class="card-body">

        <h4>Basic Info</h4>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="teamName">Game ID</label>
              <input [(ngModel)]="newGame.id" placeholder="E.g. twilight2021" type="text" class="form-control mt-1"
                     id="gameID" name="gameID" autocomplete="off">
              <small>Should be lowercase and have no spaces</small>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="group">Group</label>
              <input [(ngModel)]="newGame.name" placeholder="E.g. Twilight 2021" type="text"
                     class="form-control mt-1" name="gameName" id="gameName" autocomplete="off">
            </div>
          </div>

          <p>Make sure to change dates & costs in code first!</p>
          <button (click)="game.createGame(newGame); showCreateDialog = false;" class="btn btn-sm btn-outline-primary">Add Game</button>

        </div>
      </div>
    </div>
  </form>
</div>
