import { Component, OnInit } from '@angular/core';
import {CatchService} from '../../../services/catch.service';
import {LeaderboardService} from '../../../services/leaderboard.service';
import {Catcher} from '../../../models/catcher';
import {RunningTeam} from '../../../models/running-team';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styles: [
  ]
})
export class LeaderboardComponent implements OnInit {

  currentCatcher: string;
  currentRunner: string;

  constructor(public leaderboardService: LeaderboardService) { }

  ngOnInit(): void {
    this.leaderboardService.createLeaderboardFromFirestore();
  }
}
