import { Timestamp } from 'firebase/firestore';
import { Component } from '@angular/core';
import {GameService} from "../../../../services/game.service";
import { Game } from '../../../../models/game';

@Component({
  selector: 'app-game-settings',
  templateUrl: './game-settings.component.html',
})
export class GameSettingsComponent {
  showCreateDialog: boolean = false;
  newGame: Game = {
    id: '',
    name: '',
    currentRunnerId: 0,
    teamsMessages: [],
    costPerPerson: 5,
    submissionDeadlineOverride: [],
    submissionDeadline: Timestamp.fromDate(new Date("2024-11-15T00:00:00.000Z")),
    catcherStartTime: new Date("2024-11-23T13:00:00.000Z"),
    catcherEndTime: new Date("2024-11-23T17:45:00.000Z"),
    runnerStartTime: new Date("2024-11-23T18:00:00.000Z"),
    runnerEndTime: new Date("2024-11-23T12:00:00.000Z")
  };

  constructor(public game: GameService) {}

}
