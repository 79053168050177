<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid mx-2">
      <a class="navbar-brand text-primary fw-900" href="#">
        <span class="widegame-logo">widegame.app</span> Admin Portal
      </a>
      <div  class="d-flex">

        <form>
          <div class="input-group input-group-sm" *ngIf="gameService.currentGame && gameService.games">
            <span class="input-group-text border-light">Game:</span>
            <select [ngModel]="gameService.currentGame.id" (ngModelChange)="gameService.setCurrentGame($event, false)" class="form-select form-select-sm border-light bg-light" name="gameSelect">
              <option [ngValue]="game.id" *ngFor="let game of gameService.games">{{game.name}}</option>
            </select>
          </div>
        </form>

        <a href="https://catch.widegame.app" target="_blank" class="btn btn-light btn-sm ms-2">Catcher's App<i class="fas fa-external-link-alt ms-2"></i></a>
        <a href="https://teams.widegame.app" target="_blank" class="btn btn-light btn-sm ms-2">Teams Manager<i class="fas fa-external-link-alt ms-2"></i></a>
        <a href="https://results.widegame.app" target="_blank" class="btn btn-light btn-sm ms-2">Results <i class="fas fa-external-link-alt ms-2"></i></a>

        <div class="btn-group">
          <button class="btn btn-primary btn-sm dropdown-toggle ms-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-user-alt me-2"></i> {{ auth.user.displayName }}
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" href="#"><i class="fas fa-user-edit me-2"></i>Account</a></li>
            <li><a class="dropdown-item" (click)="auth.logout()"><i class="fas fa-sign-out-alt me-2"></i>Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <div class="bg-light">
    <ul class="nav justify-content-center main-nav">
      <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dashboard</a>
      <a class="nav-link" routerLink="/runners" routerLinkActive="active">Running Teams</a>
      <a class="nav-link" routerLink="/catchers" routerLinkActive="active">Catching Teams</a>
      <a class="nav-link" routerLink="/registration" routerLinkActive="active">Registration</a>
      <a class="nav-link" routerLink="/live-catches" routerLinkActive="active">Live Catches</a>
      <a class="nav-link" routerLink="/check-in" routerLinkActive="active">Check-in</a>
      <a class="nav-link" routerLink="/leaderboard" routerLinkActive="active">Leaderboard</a>
      <a class="nav-link" routerLink="/settings" routerLinkActive="active">Settings</a>
    </ul>
  </div>

</header>
