<div class="row m-0">
  <div class="col m-0 p-0">
    <div class="text-center signin">
      <div class="form-signin">

        <!--  Preload loading gif -->
        <img style="display: none" src="/assets/img/loading.gif" alt="Loading...">

        <h1 class="h4 mb-5"><span class="widegame-logo signin-logo">widegame.app</span></h1>

        <div *ngIf="auth.loading" class="login-loader">
          <h2>Signing in...</h2>
          <img src="/assets/img/loading.gif" alt="Loading...">
        </div>

        <form (ngSubmit)="signIn()" *ngIf="!auth.loading">
          <h2>Portal Login</h2>
          <p>Enter your login details below to access the widegame.app admin portal.</p>

          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" required
                   [(ngModel)]="email" name="Email">
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password" required
                   [(ngModel)]="password" name="Password">
            <label for="floatingPassword">Password</label>
          </div>

          <div class="text-danger fw-900 mb-3" *ngIf="auth.errorMessage">{{auth.errorMessage}}</div>

          <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>

        </form>

        <p class="mt-5 mb-3 text-muted">Eccleston Scout Group | <a class="text-primary" href="mailto:george@pears.me.uk">George Pears</a> 2021</p>


      </div>
    </div>

  </div>

  <div class="col m-0 bg-primary p-0">

  </div>
</div>
