import { Component, OnInit } from '@angular/core';
import {RunningTeam} from '../../../../models/running-team';
import {RunnerService} from '../../../../services/runner.service';

@Component({
  selector: 'app-add-runner',
  templateUrl: './add-runner.component.html'
})
export class AddRunnerComponent implements OnInit {

  newTeam = new RunningTeam();

  stage = 1;

  constructor(private runnerService: RunnerService) { }

  ngOnInit(): void {
  }

  addTeam(): void {
    this.runnerService.add(this.newTeam);
  }

}
