import { Component } from '@angular/core';
import {GameService} from "../../../services/game.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  constructor(public game: GameService) {
  }

}
