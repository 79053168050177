import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Catch} from '../models/catch';
import {map, mergeMap, take} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {GameService} from './game.service';
import {RunningTeam, RunningTeamStatus} from '../models/running-team';

@Injectable({
  providedIn: 'root'
})
export class CatchService {

  catches$: Observable<Catch[]>;
  catchesCollection: AngularFirestoreCollection<Catch>;

  status = 'none';

  constructor(private gameService: GameService, private afs: AngularFirestore) {
    this.catchesCollection = this.afs.collection<Catch>(`games/${gameService.currentGame.id}/catches`, ref => ref.orderBy('time', 'desc'));
    this.catches$ = this.catchesCollection.valueChanges();
  }

  update(catchToUpdate: Catch): void {
    this.catchesCollection.doc(catchToUpdate.id).update({...catchToUpdate});
  }

  add(catchToUpdate: Catch): void {
    console.log(catchToUpdate);
    this.status = 'adding';
    const object: any = {...catchToUpdate};
    object.time = object.time.toISOString();
    this.afs.collection<Catch>(`games/${this.gameService.currentGame.id}/catches`).doc(catchToUpdate.id).set(object).then(() => {
      this.status = 'none';
    });
  }
}
