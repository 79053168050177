import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html'
})
export class SignInComponent implements OnInit {

  email: string;
  password: string;

  constructor(public auth: AuthService, public router: Router) { }

  ngOnInit(): void {
  }

  signIn(): void {
    this.auth.signIn(this.email, this.password);
  }

}
