import {Component, OnInit} from '@angular/core';
import {CatchService} from '../../../services/catch.service';
import {Catch, CatchStatus} from '../../../models/catch';
import {RunnerService} from "../../../services/runner.service";
import {CatcherService} from "../../../services/catcher.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-catches',
  templateUrl: './catches.component.html',
  styles: []
})
export class CatchesComponent implements OnInit {

  constructor(public catchService: CatchService, public runnerService: RunnerService, public catcherService: CatcherService) {
  }

  ngOnInit(): void {
  }

  removeCatch(c: Catch): void {
    c.status = CatchStatus.Removed;
    this.catchService.update(c);
  }

  initCatch(c: Catch): void {
    this.catcherService.teams$.pipe(take(1)).subscribe(catchers => {
      this.runnerService.teams$.pipe(take(1)).subscribe(runners => {
        c.runningTeamName = runners.find(x => x.id === c.runningTeamId).name;
        c.runningTeamGroup = runners.find(x => x.id === c.runningTeamId).group;
        c.runningTeamNoMembers = runners.find(x => x.id === c.runningTeamId).members?.length;
        c.catchingTeamName = catchers.find(x => x.id === c.catchingTeamId).name;
        c.status = CatchStatus.Online;
        this.catchService.update(c);
      })
    })
  }

}
