<div class="bg-secondary py-5 text-white text-center">
  <h1>Running Teams</h1>
</div>
<div class="bg-light py-2">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="input-group">
          <input #search (input)="updateSearch($event.target.value)" pattern="[A-Za-z0-9]"
                 (keyup.enter)="searchBtn.focus()" type="search" class="form-control" placeholder="Search teams..."
                 aria-label="Recipient's username">
          <button #searchBtn (keyup)="search.focus()" class="btn btn-outline-dark" type="button"><i
            class="fas fa-search"></i></button>
        </div>
      </div>
      <div>
        <button class="btn btn-outline-dark me-2" routerLink="/print-runner-cards" target="_blank"><i class="fa-solid fa-print me-2"></i>Print Cards</button>
        <button class="btn btn-outline-dark" routerLink="/runners/add"><i class="fas fa-plus me-2"></i>Create Team</button>
      </div>
    </div>
  </div>
</div>

<div class="container mt-5">

  <div class="row" *ngIf="searchResults$ | async as searchResults">

    <div class="col-xl-4 col-md-6 col-12" *ngFor="let runner of searchResults">
      <div class="card card-clickable bg-light mb-4" routerLink="{{runner.id}}">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title" [innerHTML]="runner.name | highlightSearch: (searchTerm$ | async)"></h4>
            <h4 class="fw-normal" [innerHTML]="runner.teamNumber | number:'3.0' | highlightSearch: (searchTerm$ | async)"></h4>
          </div>
          <h6 class="card-subtitle mb-2 text-muted">
            <span class=" me-2 badge bg-primary mt-1">{{runner.category}}</span>
            <span class="me-2 badge bg-{{runner.status}} mt-1">{{runner.status}}</span>
            <span class="me-2 badge bg-secondary mt-1">{{runner.members.length}} members</span>
            <span *ngIf="runnerService.checkRunner(runner).length > 0" class="me-2 badge bg-danger mt-1">Issues</span>
            <span *ngIf="((game.currentGame.costPerPerson * runner.members.length) - (runner.amountPaid ?? 0)) == 0" class="me-2 badge bg-success mt-1">Paid</span>
          </h6>
          <p class="card-text">Group: <strong
            [innerHTML]="runner.group | highlightSearch: (searchTerm$ | async)"></strong></p>
        </div>
      </div>
    </div>

  </div>

  <h6 *ngIf="(searchResults$ | async)?.length < 1 && !runnerService.loading" class="text-center">Oops! There were no
    results for '{{search.value}}'</h6>
  <app-loader *ngIf="runnerService.loading"></app-loader>

</div>
