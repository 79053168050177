import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {take} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {Game} from '../models/game';
import {RunningTeam, RunningTeamStatus} from '../models/running-team';
import {Catch, CatchStatus} from '../models/catch';
import {Catcher} from '../models/catcher';
import {orderBy} from 'lodash';
import {GameService} from './game.service';

// TODO: Rename Catcher to CatchingTeam

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {

  overallLeaderboard: RunningTeam[];
  soloLeaderboard: RunningTeam[];
  ledLeaderboard: RunningTeam[];
  explorerLeaderboard: RunningTeam[];
  catchersLeaderboard: Catcher[];

  constructor(private afs: AngularFirestore, private gameService: GameService) {

  }

  public createLeaderboardFromFirestore() {
    // Default to data from
    const gameDoc = this.afs.doc(`games/${this.gameService.currentGame.id}`).valueChanges();
    const runnersCol = this.afs.collection<RunningTeam>(`games/${this.gameService.currentGame.id}/runningTeams`).valueChanges();
    const catchersCol = this.afs.collection(`games/${this.gameService.currentGame.id}/catchingTeams`).valueChanges();
    const catchesCol = this.afs.collection(`games/${this.gameService.currentGame.id}/catches`).valueChanges();

    combineLatest([gameDoc, runnersCol, catchesCol, catchersCol]).pipe(take(1)).subscribe(res => {
      console.log("Getting leaderboard")
      let batch = this.afs.firestore.batch();
      const runners = res[1] as Array<RunningTeam>;
      const catchers = res[3] as Array<Catcher>;
      this.overallLeaderboard = orderBy(runners, ['status', 'noCatches', 'firstCatchTime', 'checkInTime'], ['asc', 'asc', 'desc', 'asc']);
      this.soloLeaderboard = orderBy(runners.filter(r => r.category === 'Solo'), ['status', 'noCatches', 'firstCatchTime', 'checkInTime'], ['asc', 'asc', 'desc', 'asc']);
      this.ledLeaderboard = orderBy(runners.filter(r => r.category === 'Led'), ['status', 'noCatches', 'firstCatchTime', 'checkInTime'], ['asc', 'asc', 'desc', 'asc']);
      this.explorerLeaderboard = orderBy(runners.filter(r => r.category === 'Explorer'), ['status', 'noCatches', 'firstCatchTime', 'checkInTime'], ['asc', 'asc', 'desc', 'asc']);
      this.catchersLeaderboard = orderBy(catchers, [(o) => o.points || '', (o) => o.noCatches || ''], ['desc', 'desc']);
    });
  }

  // OLD LOGIC AS A BACKUP - THIS WORKS AND DOESN'T UPDATE FIRESTORE
  createLeaderboard(): void {
    const gameDoc = this.afs.doc(`games/${this.gameService.currentGame.id}`).valueChanges();
    const runnersCol = this.afs.collection<RunningTeam>(`games/${this.gameService.currentGame.id}/runningTeams`).valueChanges();
    const catchersCol = this.afs.collection(`games/${this.gameService.currentGame.id}/catchingTeams`).valueChanges();
    const catchesCol = this.afs.collection(`games/${this.gameService.currentGame.id}/catches`).valueChanges();

    combineLatest([gameDoc, runnersCol, catchesCol, catchersCol]).pipe(take(1)).subscribe(res => {
      let batch = this.afs.firestore.batch();
      const game = res[0] as Game;
      const runners = res[1] as Array<RunningTeam>;
      const catches = (res[2] as Array<Catch>).filter(c => c.status !== CatchStatus.Removed); // Ignore removed catches
      const catchers = res[3] as Array<Catcher>;
      // Times are converted to a number to be compared (hours * 60 + minutes)
      const start = 13 * 60; // 13:00
      const end = 17 * 60 + 44; // 17:44 - this actually turns out to be 17:44:59, so ends at 17:45

      // TODO: Set game's last leaderboard created

      catchers.forEach(c => {
        c.noCatches = 0;
        c.catches = [];
        c.points = 0;
      });

      runners.forEach(c => {
        c.noCatches = 0;
        c.catches = [];
      });

      // Init catches
      catches.forEach(c => {
        if (c.status === CatchStatus.Offline) {
          c.runningTeamName = runners.find(r => r.id === c.runningTeamId).name;
          c.catchingTeamName = catchers.find(ca => ca.id === c.catchingTeamId).name;
          c.status = CatchStatus.Online;
          c.reason = 'Initialised during leaderboard!';
          console.log(c);
          batch.update(this.afs.doc(`games/${this.gameService.currentGame.id}/catches/` + c.id).ref, {...c});
        }
      });
      batch.commit();
      batch = this.afs.firestore.batch();
      // Verify catches for each runner
      for (const runner of runners) {
        runner.noCatches = 0;
        runner.catches = [];
        console.log(`\n------------------- NEW RUNNER: ${runner.name} ---------------------`);
        const rCatches = catches.filter(c => c.runningTeamId === runner.id);
        for (const rCatch of rCatches) {
          console.log('\n*****CATCH**************');
          console.log(rCatch.id);
          const catchTime = this.getTime(rCatch.time);
          if (start <= catchTime && catchTime <= end) {
            // Catch is in time
            console.log('    Catch is in time: ' + rCatch.time);


            if (rCatches.some(c =>
              this.getTime(c.time) < catchTime &&
              this.getTime(c.time) > catchTime - 30 &&
              c.status === CatchStatus.Valid
            )) {
              console.log('    Already caught in last half hour - invalid');
              rCatch.status = CatchStatus.Invalid;
              rCatch.reason = 'Another catcher has already caught this team within the past half hour.';
            }

            else if (rCatches.some(c =>
              this.getTime(c.time) < catchTime &&
              this.getTime(c.time) > catchTime - 60 &&
              c.catchingTeamId === rCatch.catchingTeamId &&
              c.status === CatchStatus.Valid
            )) {
              console.log('    Already caught in last hour (same catcher) - invalid');
              rCatch.status = CatchStatus.Invalid;
              rCatch.reason = `The same catcher (${rCatch.catchingTeamName}) has already caught this team in the past hour`;
            }

            else {
              // Catch is valid
              console.log('    This catch is valid!');
              rCatch.status = CatchStatus.Valid;
              // Catch number, e.g. 1st, 2nd, 3rd, etc.
              runner.noCatches++;
              runner.catches.push(rCatch);
              rCatch.catchNumber = runner.noCatches;
              rCatch.catchValue = 11 - rCatch.catchNumber;
              const catcher = (catchers.find(c => c.id === rCatch.catchingTeamId));

              if (catcher.noCatches < 1 || !catcher.noCatches) {
                catcher.noCatches = 1;
                catcher.points = rCatch.catchValue;
                catcher.catches = [];
                catcher.catches.push(rCatch);
              }

              else {
                catcher.noCatches++;
                catcher.points += rCatch.catchValue;
                catcher.catches.push(rCatch);
              }

              if (rCatch.catchNumber === 1) {
                // If this is teams first catch, add it to runner's doc
                runner.firstCatchTime = rCatch.time;
                console.log('    ' + rCatch.id + ' is this teams first catch!');
              }
            }
          } else {
            console.log('Catch out of time');
          }
          // MOVE INSIDE CATCH IN TIME
          // If there's a valid catch half an hour before this one, invalidate it

        }
        if (this.getTime(runner.checkInTime) > 18 * 60 + 3) {
          runner.status = RunningTeamStatus.disqualified;
          runner.disqualificationReason = 'Out of time';
        }
      }
      this.soloLeaderboard = orderBy(runners.filter(r => r.category === 'Solo'), ['status', 'noCatches', 'firstCatchTime', 'checkInTime'], ['asc', 'asc', 'desc', 'asc']);
      this.ledLeaderboard = orderBy(runners.filter(r => r.category === 'Led'), ['status', 'noCatches', 'firstCatchTime', 'checkInTime'], ['asc', 'asc', 'desc', 'asc']);
      this.catchersLeaderboard = orderBy(catchers, [(o) => o.points || '', (o) => o.noCatches || ''], ['desc', 'desc']);
      for (const catcher of catchers) {
        if (catcher.noCatches < 1 || !catcher.noCatches) {
          catcher.noCatches = 0;
          catcher.points = 0;
        }
      }
      for (const c of catches) {
        batch.update(this.afs.doc(`games/${this.gameService.currentGame.id}/catches/${c.id}`).ref, c);
      }
      for (const r of runners) {
        batch.update(this.afs.doc(`games/${this.gameService.currentGame.id}/runningTeams/${r.id}`).ref, r);
      }
      for (const c of catchers) {
        batch.update(this.afs.doc(`games/${this.gameService.currentGame.id}/catchingTeams/${c.id}`).ref, c);
      }
      // batch.commit();
      console.log('\n\n-------LEADERBOARDS----------');
      console.log(this.soloLeaderboard);
      console.log(this.ledLeaderboard);
      console.log(this.catchersLeaderboard);
      console.log('\n\n-------CATCHES----------');
      console.log(catches);
      console.log('\n\n-------RUNNERS----------');
      console.log(runners);
      console.log('\n\n-------CATCHERS----------');
      console.log(catchers);
    });

  }

  getTime(time: Date): number {
    return new Date(time).getHours() * 60 + new Date(time).getMinutes();
  }

}
