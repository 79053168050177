import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {padStart} from 'lodash';
import firebase from 'firebase/compat/app';
import firestore = firebase.firestore;

/**
 * NgbDateAdapter implementation that allows using Firebase Firestore TimeStamp as a user date model.
 * https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
 */
@Injectable()
export class NgbDateFirestoreAdapter extends NgbDateAdapter<firestore.Timestamp> {
  /**
   * Converts Firestore TimeStamp to a NgbDateStruct
   */

  fromModel(ts: firestore.Timestamp): NgbDateStruct {
    if (ts instanceof firestore.Timestamp) {
      return {
        day: ts.toDate().getDate(),
        month: ts.toDate().getMonth() + 1,
        year: ts.toDate().getFullYear(),
      };
    } else {
      return null;
    }
  }

  /**
   * Converts a NgbDateStruct to a Firestore TimeStamp
   */
  toModel(ngbDate: NgbDateStruct): firestore.Timestamp {
    if (ngbDate?.year != null && ngbDate?.month != null && ngbDate?.day != null) {
      const jsDate = new Date(
        ngbDate.year ? ngbDate.year : new Date().getFullYear(),
        ngbDate.month ? ngbDate.month - 1 : new Date().getMonth() - 1,
        ngbDate.day ? ngbDate.day : new Date().getDate(),
        12
      );
      return firestore.Timestamp.fromDate(jsDate);
    } else {
      return null;
    }
  }

}

//// FORMATTER ////
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? padStart(date.day.toString(), 2, '0')
      + this.DELIMITER + padStart(date.month.toString(), 2, '0') + this.DELIMITER + date.year : '';
  }
}
