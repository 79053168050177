<div class="container mt-4">
  <button routerLink="/runners" class="btn btn-transparent ms-n3"><i class="fas fa-arrow-left me-2"></i>Running Teams
  </button>
  <h2 class="mt-3 mb-4">Add a Running Team<span *ngIf="newTeam.name">: {{newTeam.name}}</span></h2>

  <form>
    <div class="card p-2 bg-light border-0 mb-4">
      <div class="card-body">

        <h4>Basic Info</h4>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="teamName">Team Name</label>
              <input [(ngModel)]="newTeam.name" placeholder="E.g. The Winners" type="text" class="form-control mt-1"
                     id="teamName" name="teamName" autocomplete="off">
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="group">Group</label>
              <input [(ngModel)]="newTeam.group" placeholder="E.g. Eccleston Scout Group" type="text"
                     class="form-control mt-1" name="teamName" id="group">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card p-2 bg-light border-0 mb-4">
      <div class="card-body">

        <h4>Category</h4>
        <div class="row pt-2">
          <!--          TODO: Make this dynamic based on games - e.g. Twilight doesn't have explorer & network teams-->
          <!--          TODO: in teams manager, calculate this based on team member info -->
          <div class="col">
            <div class="card card-team-type" [class.active]="newTeam.category == 'Led'"
                 (click)="newTeam.category = 'Led'">
              <div class="card-body">
                <h6>Led</h6>
                <p class="mb-0">Scout teams aged 10.5 to 14 with a leader. 4 to 7 members + leaders.</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-team-type" [class.active]="newTeam.category == 'Solo'"
                 (click)="newTeam.category = 'Solo'">
              <div class="card-body">
                <h6>Solo</h6>
                <p class="mb-0">Scout teams aged 10.5 to 14 without a leader. 4 to 7 members.</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-team-type" [class.active]="newTeam.category == 'Explorer'"
                 (click)="newTeam.category = 'Explorer'">
              <div class="card-body">
                <h6>Explorer</h6>
                <p class="mb-0">Explorer teams with 2 or more members aged 13.5 to 18. 4 to 7 members.</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-team-type" [class.active]="newTeam.category == 'Network'"
                 (click)="newTeam.category = 'Network'">
              <div class="card-body">
                <h6>Network</h6>
                <p class="mb-0">Teams with 2 or more members over the age of 18. 4 to 7 members.</p>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-team-type" [class.active]="newTeam.category == 'Veteran'"
                 (click)="newTeam.category = 'Veteran'">
              <div class="card-body">
                <h6>Veteran</h6>
                <p class="mb-0">Teams with two or more members over the age of 25. 4 to 7 members.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card p-2 bg-light border-0 mb-4">
      <div class="card-body">

        <h4>Team Point of Contact (Leader)</h4>
        <div class="row mb-3">
          <div class="col">
            <div class="form-group">
              <label for="teamName">Contact Name</label>
              <input [(ngModel)]="newTeam.contactName" placeholder="E.g. John Smith" type="text"
                     class="form-control mt-1" id="contactName" name="contactName">
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="group">Contact Email</label>
              <input [(ngModel)]="newTeam.contactEmail" placeholder="E.g. johnsmith@gmail.com" type="text"
                     class="form-control mt-1" name="contactEmail" id="contactEmail">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="teamName">Contact Address</label>
              <input [(ngModel)]="newTeam.contactAddress" placeholder="E.g. 7 Snape Rake Lane, Goosnargh, PR3 2EU"
                     type="text" class="form-control mt-1" id="contactAddress" name="contactAddress">
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="group">Contact Phone</label>
              <input [(ngModel)]="newTeam.contactPhone" placeholder="E.g. 07123 456 789" type="tel"
                     class="form-control mt-1" name="contactPhone" id="contactPhone">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card p-2 bg-light border-0 mb-4">
      <div class="card-body">
        <h4>Team Members</h4>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="teamName">Number of Team Members (Including Leaders)</label>
              <input [(ngModel)]="newTeam.noMembers" placeholder="Between 4 and 7" type="number" min="4" max="7"
                     class="form-control mt-1" id="noMembers" name="noMembers">
            </div>
          </div>
        </div>
      </div>


    </div>

    <button class="btn btn-primary btn-lg mt-2 text-end" (click)="addTeam()">Add Team</button>
  </form>
</div>
