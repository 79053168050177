import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: ToastInfo[] = [];

  show(body: string, header: string = null, bg: string = null): void {
    this.toasts.push({ header, body, bg });
  }
}

export interface ToastInfo {
  header: string;
  body: string;
  delay?: number;
  bg?: string;
}
