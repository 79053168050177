import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {RunnerService} from '../../../services/runner.service';
import {RunningTeam, RunningTeamStatus} from '../../../models/running-team';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {GameService} from "../../../services/game.service";

@Component({
  selector: 'app-runners',
  templateUrl: './runners.component.html'
})
export class RunnersComponent implements OnInit {

  searchTerm$ = new BehaviorSubject<string>(''); // Use BehaviorSubject, not Subject!!
  searchResults$: Observable<RunningTeam[]>;

  @ViewChild('search') searchElement: ElementRef;

  // If user starts typing, focus input to search & enter value just type TODO: Fix this!!
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (document.activeElement !== this.searchElement.nativeElement && /^[a-z0-9]$/i.test(event.key)) {
      this.searchElement.nativeElement.focus();
      this.searchElement.nativeElement.value = event.key;
    }
  }

  constructor(public runnerService: RunnerService, public game: GameService) {
    this.searchResults$ = this.searchTerm$.pipe(
      mergeMap(search => this.runnerService.teams$.pipe(
          distinctUntilChanged(),
          map(teams => {
            return teams.filter(team =>
              team.name.toLowerCase().includes(search.toLowerCase()) ||
              team.group.toLowerCase().includes(search.toLowerCase()) ||
              team.id.includes(search));
          })
        )
      )
    );
  }

  updateSearch(search: string): void {
    this.searchTerm$.next(search);
  }

  ngOnInit(): void {
  }

}
