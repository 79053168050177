import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Catch, CatchStatus} from '../../../../../models/catch';
import {formatDate} from '@angular/common';
import {CatchService} from '../../../../../services/catch.service';
import {CatcherService} from '../../../../../services/catcher.service';
import {RunnerService} from '../../../../../services/runner.service';

@Component({
  selector: 'app-add-catch',
  templateUrl: './add-catch.component.html',
  styles: [
  ]
})
export class AddCatchComponent implements OnInit {

  mins: 0;
  hours: 0;

  runnerID: string;
  catcherID: string;

  constructor(@Inject(LOCALE_ID) private locale: string, public catchService: CatchService, public catcherService: CatcherService,
              public runnerService: RunnerService) { }

  ngOnInit(): void {
  }

  saveCatch(): void {
    const newCatch = new Catch();
    newCatch.runningTeamId = this.runnerID;
    const date = new Date(Date.now());
    date.setSeconds(0);
    date.setMinutes(this.mins);
    date.setHours(this.hours);
    newCatch.time = date;
    newCatch.method = 'manual';
    newCatch.reason = 'Added manually from Portal';
    newCatch.catchingTeamId = this.catcherID;
    newCatch.id = `${formatDate(newCatch.time, 'HH:mm', this.locale)}-${newCatch.runningTeamId}-${newCatch.catchingTeamId}`;
    newCatch.status = CatchStatus.Offline;
    console.log(newCatch.id);
    this.catchService.add(newCatch);
  }

  getDate(): Date {
    const date = new Date(Date.now());
    date.setMinutes(this.mins);
    date.setHours(this.hours);
    return date;
  }

}
