import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {RunningTeam, RunningTeamStatus} from "../../../models/running-team";
import {ScannerService} from "../../../services/scanner.service";
import {RunnerService} from "../../../services/runner.service";
import {GameService} from "../../../services/game.service";
import {distinctUntilChanged, map, mergeMap} from "rxjs/operators";
import {Catch, CatchStatus} from "../../../models/catch";
import {NgxScannerQrcodeComponent, ScannerQRCodeConfig} from "ngx-scanner-qrcode";

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
})
export class CheckInComponent implements AfterViewInit, OnDestroy{
  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  config = {
    isBeep: false,
    canvasStyles: [{lineWidth: 1, strokeStyle: '#7413dc', fillStyle: '#7413dc80'}, {
      font: '17px serif',
      strokeStyle: '#fff0',
      fillStyle: '#ff0000'
    }],
    constraints: {
      video: {
      },
    },
  };

  scanMethod = true;

  searchTerm$ = new BehaviorSubject<string>(''); // Use BehaviorSubject, not Subject!!
  searchResults$: Observable<RunningTeam[]>;

  constructor(public scannerService: ScannerService, public runnerService: RunnerService, public game: GameService) {
    this.searchResults$ = this.searchTerm$.pipe(
      mergeMap(search => this.runnerService.teams$.pipe(
          distinctUntilChanged(),
          map(teams => {
            return teams.filter(team =>
              team.status === RunningTeamStatus.registered &&
              (team.name.toLowerCase().includes(search.toLowerCase()) ||
                team.group.toLowerCase().includes(search.toLowerCase()) ||
                team.id.includes(search)));
          })
        )
      )
    );
  }

  updateSearch(search: string): void {
    this.searchTerm$.next(search);
  }

  getValidCatches(catches: Catch[]): number {
    return catches.filter(x => x.status == CatchStatus.Valid).length;
  }


  ngAfterViewInit() {
    this.action?.isReady.subscribe(() => {
      console.log('Test')
      this.handle(this.action, 'start');
    });
  }

  ngOnDestroy(): void {
    if (this.action) {
      this.handle(this.action, 'stop');
    }
  }


  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r));
    } else if (fn === 'torcher') {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert('This camera does not have flash enabled. Try \'Switch Camera\'.'));
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r));
    }
  }

  scanSuccess(runnerId: string): void {
    this.updateSearch(runnerId);
  }
}
