<div class="bg-warning py-5 text-center">
  <h1>Add Catch</h1>
</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-3 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Time</span>
        </div>
        <input [(ngModel)]="hours" placeholder="00" type="number" min="0" max="24" class="form-control"
               id="hour" autocomplete="off">
        <input [(ngModel)]="mins" placeholder="00" type="number" min="0" max="59" class="form-control"
               id="mins" autocomplete="off">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Catcher</span>
        </div>
        <select class="form-control" id="catcher" [(ngModel)]="catcherID">
          <option value="" disabled selected>Select Catcher</option>
          <option *ngFor="let catcher of (catcherService.teams$ | async)" [value]="catcher.id">{{catcher.name }} ({{catcher.contactName}})</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Runner</span>
        </div>
        <select class="form-control" id="runner" [(ngModel)]="runnerID">
          <option value="" disabled selected>Select Runner</option>
          <option *ngFor="let runner of (runnerService.teams$ | async)" [value]="runner.id">{{runner.id}} - {{runner.name }}</option>
        </select>
      </div>
    </div>
  </div>

  <button [disabled]="catchService.status == 'adding'" class="btn btn-primary" (click)="saveCatch()">Save</button>

  <p class="mt-3">{{getDate()}}</p>
</div>


