<div class="container mt-5">
  <h1>Welcome, {{ auth.user.displayName }}</h1>
  <p class="mb-4">Here you can manage all aspects of the game. Below are some handy stats & info for organising the
    game.</p>

  <div *ngIf="runnerService.teams$ | async as runners">
    <div class="row">

      <div class="col-md-4">
        <div class="card border-0 bg-light">
          <div class="card-body">
            <h4>Catering Info</h4>
            <div class="d-flex justify-content-between align-items-center mt-1 mb-3">
              <div>
                <p class="mb-0">Number of runners eating:</p>
                <small>This does not include catchers / leaders!</small>
              </div>
              <h4 class="text-end">{{getNumberEating(runners)}}</h4>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Dietary Requirements</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let req of getDietaryReqs(runners)">
                <th scope="row">{{req.name}}</th>
                <td>{{req.dietaryReqs}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card border-0 bg-light">
          <div class="card-body">
            <h4>Armband Sorting</h4>
            <div class="d-flex justify-content-between align-items-center mt-1 mb-3">
              <div>
                <p class="mb-0">Total number of runners:</p>
                <small>The below doesn't account for teams larger than 7 or smaller than 4.</small>
              </div>
              <h4 class="text-end ms-4">{{getNumberOfRunners(runners)}}</h4>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">No. Members</th>
                <th scope="col">No. Teams</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let req of getTeamDivisions(runners); index as i">
                <th scope="row">{{i + 4}}</th>
                <td>{{req}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
