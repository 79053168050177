<div class="print-wrapper">
  <div *ngFor="let chunk of chunk((runnerService.teams$ | async));">
    <!-- FRONT -->
    <div *ngFor="let team of chunk; index as i" class="runner-card" [class.third-card]="(i+1) % 3 == 0"
         [class.first-card]="(i+3) % 3 == 0">
      <div class="row">
        <!--    Left -->
        <div class="col-6 p-5 text-center">
          <h3 class="mb-3">Contact</h3>
          <p>For any queries / retirements please contact one of the numbers below. Do not retire without letting the
            organisation team know.</p>
          <h5 class="mt-4 mb-3">George Pears: 07821193045</h5>
          <h5>Paul Kirby: 07791169798</h5>
          <p class="mb-0 mt-4 small fw-bold">Twilight 2023</p>
          <p class="mb-0 mt-0 small">widegame.app</p>
        </div>
        <!--    Right -->
        <div class="col-6 p-5 text-center">
          <h1>Twilight for Abbie</h1>
          <h1 class="fw-normal">2023</h1>
          <h4 class="mt-4">Runner:</h4>
          <h4 class="fw-normal">{{team.name}}</h4>
          <h4 class="mt-4">Group:</h4>
          <h4 class="fw-normal">{{team.group}}</h4>
        </div>
      </div>
    </div>

    <!-- BACK -->
    <div *ngFor="let team of chunk; index as i" class="runner-card" [class.third-card]="(i+1) % 3 == 0"
         [class.first-card]="(i+3) % 3 == 0">
      <div class="row">
        <!--    Left -->
        <div class="col-6 p-4 text-center">
          <table class="table table-bordered border-1 border-dark">
            <tr>
              <th></th>
              <th>Catcher's Team Name</th>
              <th>Time</th>
            </tr>
            <tr *ngFor="let x of [].constructor(7); index as i">
              <td class="pt-2 pb-1">{{i + 1}}</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        <!--    Right -->
        <div class="col-6 p-4 text-center">
          <h2>Your QR Code</h2>
          <p class="small">We're now using an app to speed up the catching process and make the leaderboard more accurate. Show this code to the catcher.</p>
          <p class="small">The table on the left is for your use to keep track of your catches.</p>
          <div class="row">
            <div class="col-6">
              <h3 class="fw-normal mt-3">Team No.</h3>
              <h3>{{team.teamNumber | number:'3.0'}}</h3>
            </div>
            <div class="col-6 text-start">
              <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&ecc=Q&data={{team.id}}" alt="{{team.id}}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
