import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RunnerService} from '../../../../services/runner.service';
import {RunningTeam} from '../../../../models/running-team';
import {ScannerService} from '../../../../services/scanner.service';
import {RunningTeamMember} from '../../../../models/running-team-member';
import {NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GameService} from "../../../../services/game.service";
import {ToastService} from "../../../../services/toast.service";
import firebase from 'firebase/compat/app';
import firestore = firebase.firestore;

@Component({
  selector: 'app-view-runner',
  templateUrl: './view-runner.component.html',
  styles: [
  ]
})
export class ViewRunnerComponent implements OnInit {


  teamEdited = false
  protected readonly NgbDate = NgbDate;
  constructor(route: ActivatedRoute, public runnerService: RunnerService, public toastService: ToastService,
              private modalService: NgbModal, private router: Router, public game: GameService) {
    runnerService.setRunner(route.snapshot.params.id);
  }

  ngOnInit(): void {
  }
  saveTeam(team: RunningTeam): void {
    let goodToSave = true;
    team.members.forEach((member, i) => {
      if (member.dob !== null && member.dob !== undefined && !(member.dob instanceof firestore.Timestamp)) {
        this.toastService.show('That date of birth doesn\'t look quite right, it wasn\'t saved. Please try again.', 'Invalid date', 'danger');
        team.members[i].dob = null;
        goodToSave = false;
      }
    });
    if (goodToSave) {
      this.runnerService.update(team, 0, true);
      this.teamEdited = false;
    }
  }

  addMember(team: RunningTeam): void {
    const member = {} as RunningTeamMember;
    member.wantsFood = true;
    if (team.members == null) {
      team.members = new Array<RunningTeamMember>();
    }
    team.members.push(member);
  }

  // TODO: Rewrite all of this & runners service - it's horrible
  makeTeamLeader(team: RunningTeam, memberIndex: number): void {
    team.members.unshift(team.members.splice(memberIndex, 1)[0]);
  }

  moveToTeam(team: RunningTeam, memberIndex: number, newTeam: RunningTeam): void {
    const member = team.members[memberIndex];
    if (newTeam.members == null) {
      newTeam.members = [];
    }
    newTeam.members.push(member);
    // Remove from current team
    this.runnerService.update(newTeam, 0);
    team.members.splice(memberIndex, 1);
    this.runnerService.update(team, 0, true);
  }

  confirmDeleteMember(content, team: RunningTeam, i: number): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
        if (result === 'Delete') {
          team.members.splice(i, 1);
          this.runnerService.update(team, 0, true);
        }
      },
    );
  }

confirmDeleteTeam(content, team): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
        if (result === 'Delete') {
          this.teamEdited = false;
          this.runnerService.delete(team.id);
          this.router.navigate(['/']);
        }
      },
    );
  }

  filter(currentTeamId: string, teams?: RunningTeam[]): RunningTeam[] {
    let toReturn: RunningTeam[];
    toReturn = teams?.filter(x => x.id !== currentTeamId);
    return toReturn;
  }

  setDays(date: number, newDays: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDays);
    return newDate;
  }

}
