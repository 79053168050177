<div class="d-flex vh-100 text-center text-white bg-dark">

<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">

  <main class="px-3 my-auto">
    <h1 class="">404</h1>
    <p class="lead my-4">This page could not be found. Try going back to the dashboard.</p>
    <p class="lead">
      <a href="#" class="btn btn-lg btn-secondary fw-bold border-white bg-white">Dashboard</a>
    </p>
  </main>

</div>





</div>
