import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RunnersComponent} from './components/pages/runners/runners.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {NotFoundComponent} from './components/errors/not-found/not-found.component';
import {MainComponent} from './components/layout/main/main.component';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from '../environments/environment';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {SignInComponent} from './components/auth/sign-in/sign-in.component';
import {FormsModule} from '@angular/forms';
import {AdminGuard} from './guards/admin.guard';
import {CatchersComponent} from './components/pages/catchers/catchers.component';
import {ToastsComponent} from './components/layout/toasts/toasts.component';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, DecimalPipe} from '@angular/common';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { AddRunnerComponent } from './components/pages/runners/add-runner/add-runner.component';
import { ViewRunnerComponent } from './components/pages/runners/view-runner/view-runner.component';
import { LoaderComponent } from './components/layout/loader/loader.component';
import { RegistrationComponent } from './components/pages/registration/registration.component';
import {HttpClientModule} from '@angular/common/http';
import { CatchesComponent } from './components/pages/catches/catches.component';
import { SafePipe } from './pipes/safe.pipe';
import { LeaderboardComponent } from './components/pages/leaderboard/leaderboard.component';
import { AddCatchComponent } from './components/pages/catches/add/add-catch/add-catch.component';
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import { PrintRunnerCardsComponent } from './components/pages/runners/print-runner-cards/print-runner-cards.component';
import {CustomDateParserFormatter, NgbDateFirestoreAdapter} from "./ngb-date-firestore-adapter.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { CheckInComponent } from './components/pages/check-in/check-in.component';
import {LOAD_WASM, NgxScannerQrcodeModule} from "ngx-scanner-qrcode";
import { GameSettingsComponent } from './components/pages/settings/game-settings/game-settings.component';
import { SettingsComponent } from './components/pages/settings/settings.component';

LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM', res));

@NgModule({
  declarations: [
    AppComponent,
    RunnersComponent,
    HeaderComponent,
    DashboardComponent,
    NotFoundComponent,
    MainComponent,
    SignInComponent,
    CatchersComponent,
    ToastsComponent,
    HighlightSearchPipe,
    AddRunnerComponent,
    ViewRunnerComponent,
    LoaderComponent,
    RegistrationComponent,
    CatchesComponent,
    SafePipe,
    LeaderboardComponent,
    AddCatchComponent,
    PrintRunnerCardsComponent,
    CheckInComponent,
    GameSettingsComponent,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    NgxScannerQrcodeModule,
  ],
  providers: [
    AdminGuard,
    DecimalPipe,
    {provide: NgbDateAdapter, useClass: NgbDateFirestoreAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
