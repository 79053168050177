export class Catch {
  id: string;
  time: Date;
  lat?: number;
  long?: number;
  catchingTeamId: string;
  catchingTeamName: string;
  runningTeamName: string;
  runningTeamGroup: string;
  runningTeamNoMembers: number;
  runningTeamId: string;
  method: string; // Scan or Manual
  image: string; // Actual image should not be uploaded to firestore - instead cloud storage

  discrepancy: string;

  catchNumber?: number; // Running teams 1st, 2nd, 3rd, etc. catch.
  catchValue?: number; // Catcher's value of catch

  status: CatchStatus;
  reason?: string;
}
export enum CatchStatus {
  Offline = 'Offline',
  Error = 'Error', // Team id not found
  Online = 'Online', // Team id found, but not checked
  Valid = 'Valid', // Catch is fine & abides by rules
  Invalid = 'Invalid', // Catch isn't valid - see reason (e.g. out of time)
  Removed = 'Removed'
}
