import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {RunningTeam} from '../models/running-team';
import {map, mergeMap, tap} from 'rxjs/operators';
import {GameService} from './game.service';
import {Catcher} from '../models/catcher';

@Injectable({
  providedIn: 'root'
})
export class CatcherService {

  teams$: Observable<Catcher[]>;

  constructor(private afs: AngularFirestore, private gameService: GameService) {
    this.teams$ = this.getCatcherCollection().pipe(
      mergeMap(collection => collection.valueChanges()),
      // tap(() => this.loading = false),
    );
  }

  private getCatcherCollection(): Observable<AngularFirestoreCollection<Catcher>> {
    // Return observable of the collection for the current game
    return this.gameService.currentGame$.pipe(map(game => {
      return this.afs.collection<Catcher>(`games/${game.id}/catchingTeams`);
    }));
  }
}
