import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {Game} from '../models/game';
import {mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class GameService {

  games$: Observable<Game[]>;
  currentGame$: Observable<Game>; // Just for this session, set by default as currentGame from firestore settings document
  games: Game[];
  currentGame: Game; // Just for this session, set by default as currentGame from firestore settings document

  constructor(public afs: AngularFirestore) {
    // @ts-ignore
    this.getCurrentGame();
    this.games$ = this.afs.collection<Game>('games').valueChanges();
    this.games$.subscribe(games => this.games = games);
    this.currentGame$.subscribe(game => this.currentGame = game);
  }

  getCurrentGame(): Observable<Game> {
    return this.currentGame$ = this.afs.doc<Game>('settings/currentGame').valueChanges().pipe(mergeMap(current => {
      return this.afs.doc<Game>(`games/${current.id}`).valueChanges();
    }));
  }

  setCurrentGame(gameID: any, setInFirestore: boolean): void {
    const newGame = this.games.find(x => x.id === gameID);
    this.currentGame = newGame; // Just for the UI to update properly, doesn't actually change anything
    this.currentGame$ = this.afs.doc<Game>(`games/${newGame.id}`).valueChanges();
    if (setInFirestore) {
      this.afs.doc('settings/currentGame').update({id: gameID, name: newGame.name}).finally(() => {
        // window.location.reload();
        console.log(this.currentGame);
        console.log(this.games);
      });
    }
  }

  createGame(game: Game): void {
    // Check games ID doesn't already exist
    if (game.id?.length > 2 && game.name?.length > 2 &&  this.games.find(x => x.id === game.id) == undefined) {
      this.afs.doc('games/' + game.id).set(game).finally(() => {
        // window.location.reload();
        console.log(this.currentGame);
        console.log(this.games);
      });
    }
  }
}

