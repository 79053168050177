<div class="bg-primary text-white py-5 text-center">
  <h1>Leaderboard</h1>
</div>
<div class="container mt-5">
  <p>Leaderboard should generate from online data. To generate locally, click below:</p>
  <button class="btn btn-primary mb-4" (click)="leaderboardService.createLeaderboard()">Create Leaderboard (Locally)</button>

  <div *ngIf="leaderboardService.overallLeaderboard" class="mt-4">
    <h4>Overall Leaderboard:</h4>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Pos.</th>
        <th scope="col">Team Name</th>
        <th scope="col">Category</th>
        <th scope="col">Catches</th>
        <th scope="col">First Catch</th>
        <th scope="col">Check-in</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of leaderboardService.overallLeaderboard; let i = index" (click)="currentRunner = team.id" [class.table-danger]="team.status === 'Disqualified'">
        <th scope="row">{{(i+1) === 1 ? '🥇' : (i+1) === 2 ? '🥈' : (i+1) === 3 ? '🥉' : (i+1)}}</th>
        <td><b>{{team.name}}</b> ({{team.group}})</td>
        <td>{{team.category}}</td>
        <td>{{team.noCatches}}</td>
        <td>{{team.firstCatchTime | date: 'HH:mm'}}</td>
        <td *ngIf="team.status !== 'Disqualified'">{{team.checkInTime | date: 'HH:mm:ss'}}</td>
        <td *ngIf="team.status === 'Disqualified'">{{team.disqualificationReason}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="leaderboardService.soloLeaderboard" class="mt-5">
    <h4>Solo Teams:</h4>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Pos.</th>
        <th scope="col">Team Name</th>
        <th scope="col">Catches</th>
        <th scope="col">First Catch</th>
        <th scope="col">Check-in</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of leaderboardService.soloLeaderboard; let i = index" (click)="currentRunner = team.id" [class.table-danger]="team.status === 'Disqualified'">
        <th scope="row">{{(i+1) === 1 ? '🥇' : (i+1) === 2 ? '🥈' : (i+1) === 3 ? '🥉' : (i+1)}}</th>
        <td><b>{{team.name}}</b> ({{team.group}})</td>
        <td>{{team.noCatches}}</td>
        <td>{{team.firstCatchTime | date: 'HH:mm'}}</td>
        <td *ngIf="team.status !== 'Disqualified'">{{team.checkInTime | date: 'HH:mm:ss'}}</td>
        <td *ngIf="team.status === 'Disqualified'">{{team.disqualificationReason}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="leaderboardService.ledLeaderboard" class="mt-5">
    <h4>Led Teams:</h4>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Pos.</th>
        <th scope="col">Team Name</th>
        <th scope="col">Catches</th>
        <th scope="col">First Catch</th>
        <th scope="col">Check-in</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of leaderboardService.ledLeaderboard; let i = index" (click)="currentRunner = team.id" [class.table-danger]="team.status === 'Disqualified'">
        <th scope="row">{{(i+1) === 1 ? '🥇' : (i+1) === 2 ? '🥈' : (i+1) === 3 ? '🥉' : (i+1)}}</th>
        <td><b>{{team.name}}</b> ({{team.group}})</td>
        <td>{{team.noCatches}}</td>
        <td>{{team.firstCatchTime | date: 'HH:mm'}}</td>
        <td *ngIf="team.status !== 'Disqualified'">{{team.checkInTime | date: 'HH:mm:ss'}}</td>
        <td *ngIf="team.status === 'Disqualified'">{{team.disqualificationReason}}</td>
      </tr>
      </tbody>
    </table>
  </div>


  <div *ngIf="leaderboardService.explorerLeaderboard" class="mt-5">
    <h4>Explorer Teams:</h4>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Pos.</th>
        <th scope="col">Team Name</th>
        <th scope="col">Catches</th>
        <th scope="col">First Catch</th>
        <th scope="col">Check-in</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of leaderboardService.explorerLeaderboard; let i = index" (click)="currentRunner = team.id" [class.table-danger]="team.status === 'Disqualified'">
        <th scope="row">{{(i+1) === 1 ? '🥇' : (i+1) === 2 ? '🥈' : (i+1) === 3 ? '🥉' : (i+1)}}</th>
        <td><b>{{team.name}}</b> ({{team.group}})</td>
        <td>{{team.noCatches}}</td>
        <td>{{team.firstCatchTime | date: 'HH:mm'}}</td>
        <td *ngIf="team.status !== 'Disqualified'">{{team.checkInTime | date: 'HH:mm:ss'}}</td>
        <td *ngIf="team.status === 'Disqualified'">{{team.disqualificationReason}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="leaderboardService.catchersLeaderboard" class="mt-5">
    <h4>Catching Teams:</h4>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Pos.</th>
        <th scope="col">Team Name</th>
        <th scope="col">Points</th>
        <th scope="col">Catches</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of leaderboardService.catchersLeaderboard; let i = index" (click)="currentCatcher = team.id">
        <th scope="row">{{(i+1) === 1 ? '🥇' : (i+1) === 2 ? '🥈' : (i+1) === 3 ? '🥉' : (i+1)}}</th>
        <td>{{team.name}} ({{team.contactName}})</td>
        <td>{{team.points}}</td>
        <td>{{team.noCatches}}</td>
      </tr>
      </tbody>
    </table>
  </div>

</div>

<!--<p>{{currentRunner | json}}</p>-->
<!--<div class="modal" tabindex="-1" *ngIf="currentRunner">-->
<!--  <div class="modal-dialog">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h5 class="modal-title">{{currentRunner.name}} ({{currentRunner.id}})</h5>-->
<!--        <button (click)="currentRunner = null" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <p>Modal body text goes here.</p>-->
<!--      </div>-->
<!--      <div class="modal-footer">-->
<!--        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--        <button type="button" class="btn btn-primary">Save changes</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
