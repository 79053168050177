import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {RunnersComponent} from './components/pages/runners/runners.component';
import {NotFoundComponent} from './components/errors/not-found/not-found.component';
import {MainComponent} from './components/layout/main/main.component';
import {AngularFireAuthGuard, canActivate, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/compat/auth-guard';
import {SignInComponent} from './components/auth/sign-in/sign-in.component';
import {AdminGuard} from './guards/admin.guard';
import {CatchersComponent} from './components/pages/catchers/catchers.component';
import {AddRunnerComponent} from './components/pages/runners/add-runner/add-runner.component';
import {ViewRunnerComponent} from './components/pages/runners/view-runner/view-runner.component';
import {RegistrationComponent} from './components/pages/registration/registration.component';
import {CatchesComponent} from './components/pages/catches/catches.component';
import {LeaderboardComponent} from './components/pages/leaderboard/leaderboard.component';
import {AddCatchComponent} from './components/pages/catches/add/add-catch/add-catch.component';
import {PrintRunnerCardsComponent} from "./components/pages/runners/print-runner-cards/print-runner-cards.component";
import {CheckInComponent} from "./components/pages/check-in/check-in.component";
import {SettingsComponent} from "./components/pages/settings/settings.component";
import {GameSettingsComponent} from "./components/pages/settings/game-settings/game-settings.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AngularFireAuthGuard, AdminGuard], // Needs angular to redirect to sign-in if not logged in
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [AdminGuard], // Only needs to check for auth status, as already logged in to be here
      },
      {
        path: 'runners',
        children: [
          {
            path: '',
            component: RunnersComponent,
            canActivate: [AdminGuard],
          },

          {
            path: 'add',
            component: AddRunnerComponent,
            canActivate: [AdminGuard],
          },
          {
            path: ':id',
            component: ViewRunnerComponent,
            canActivate: [AdminGuard],
          }
        ]
      },
      {
        path: 'catchers',
        component: CatchersComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'live-catches',
        component: CatchesComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'add-catch',
        component: AddCatchComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'registration',
        component: RegistrationComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'check-in',
        component: CheckInComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'leaderboard',
        component: LeaderboardComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AdminGuard],
        children: [
          {
            path: 'game',
            component: GameSettingsComponent,
            canActivate: [AdminGuard],
          },
        ]
      },
    ],
  },
  { path: 'sign-in',  component: SignInComponent, ...canActivate(redirectLoggedInToItems)},
  { path: 'print-runner-cards', component: PrintRunnerCardsComponent,
    canActivate: [AngularFireAuthGuard, AdminGuard], // Needs angular to redirect to sign-in if not logged in
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
