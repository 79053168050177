<div class="bg-dark text-white py-5 text-center">
  <h1>Settings</h1>
</div>
<div class="container mt-5">
  <div class="row">
    <div class="col-4">

      <div class="bg-light pb-2">
        <h4 class="p-3">Settings</h4>

        <div routerLink="/settings/game" class="d-flex justify-content-start align-items-center m-1 mb-2 p-2 card-clickable">
          <i class="fa-solid fa-list p-3 bg-white rounded-circle me-3"></i>
          <div>
            <h5 class="mb-0">Game</h5>
            <p class="mb-0">Manage add games & set the current game</p>
          </div>
        </div>

        <p class="px-3 mb-1 mt-3 small fw-bold text-primary">{{game.currentGame.name}} settings:</p>

        <div class="d-flex justify-content-start align-items-center m-1 mb-2 p-2 card-clickable">
          <i class="fa-regular fa-clock p-3 bg-white rounded-circle me-3"></i>
          <div>
            <h5 class="mb-0">Timings</h5>
            <p class="mb-0">Set deadlines and game start & end times</p>
          </div>
        </div>

        <div class="d-flex justify-content-start align-items-center m-1 mb-2 p-2 card-clickable">
          <i class="fa-solid fa-user-plus p-3 bg-white rounded-circle me-3"></i>
          <div>
            <h5 class="mb-0">Team Limits</h5>
            <p class="mb-0">Set sign-up limits</p>
          </div>
        </div>

        <div class="d-flex justify-content-start align-items-center m-1 mb-2 p-2 card-clickable">
          <i class="fa-solid fa-money-bill p-3 bg-white rounded-circle me-3"></i>
          <div>
            <h5 class="mb-0">Cost & Payments</h5>
            <p class="mb-0">Set cost per person / team</p>
          </div>
        </div>

        <div class="d-flex justify-content-start align-items-center m-1 mb-2 p-2 card-clickable">
          <i class="fa-solid fa-user-tag p-3 bg-white rounded-circle me-3"></i>
          <div>
            <h5 class="mb-0">Catch Rules</h5>
            <p class="mb-0">Set rules for catches</p>
          </div>
        </div>

        <div class="d-flex justify-content-start align-items-center m-1 mb-2 p-2 card-clickable">
          <i class="fa-regular fa-comment p-3 bg-white rounded-circle me-3"></i>
          <div>
            <h5 class="mb-0">Messages</h5>
            <p class="mb-0">In-app messages to users</p>
          </div>
        </div>

      </div>

    </div>
    <div class="col-8">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
