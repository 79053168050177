<div class="bg-warning py-5 text-center">
  <h1>Live Catches</h1>
</div>
<div class="bg-light py-2">
  <div class="container">
    <div class="d-flex justify-content-end">
      <button class="btn btn-outline-dark" routerLink="/add-catch"><i class="fas fa-plus me-2"></i>Create Catch
      </button>
    </div>
  </div>
</div>

<div class="container mt-5" *ngIf="catchService.catches$ | async as catches">

  <div class="card bg-light border-0 mb-3" *ngFor="let catch of catches">
    <div class="row">
      <div class="col-md-6">
        <div class="card-body">
          <p>Time: <strong>{{catch.time | date: 'HH:mm:ss'}}</strong></p>
          <p>Runner: <strong>{{catch.runningTeamName}}</strong> - {{catch.runningTeamGroup}} ({{catch.runningTeamId}})</p>
          <p>Catcher: <strong>{{catch.catchingTeamName}}</strong> ({{catch.catchingTeamId}})</p>
          <p>Status: <strong>{{catch.status}}</strong>, Reason: {{catch.reason}}</p>
          <p>Method: <strong>{{catch.method}}</strong></p>
          <p>Catch No.: <strong>{{catch.catchNumber}}</strong> | Catch value: <strong>{{catch.catchValue}}</strong></p>
          <p class="text-danger" *ngIf="catch.discrepancy">Discrepancy: <strong>{{catch.discrepancy}}</strong></p>
          <button [disabled]="catch.status === 'Removed'" class="btn btn-danger text-white me-2" (click)="removeCatch(catch)">Remove Catch</button>
          <button class="btn btn-info text-white me-2" (click)="initCatch(catch)">Initialize Catch</button>
        </div>
      </div>
      <div class="col-md-4">
        <iframe *ngIf="catch.lat else noLoc"
          width="100%"
          height="300px"
          style="border:0"
          loading="lazy"
          allowfullscreen
          [src]="'https://www.google.com/maps/embed/v1/place?key=AIzaSyAwbjIZk3bV_900Xi7mvcVv8hzKlFBP7zo&q='+catch.lat+','+catch.long+'&zoom=15' | safe">
        </iframe>
        <ng-template #noLoc>No location data</ng-template>
      </div>
      <div class="col-md-2">
        <img width="100%" src="{{catch.image | getDownloadURL}}">
      </div>
    </div>
  </div>

</div>
