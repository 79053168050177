import { Component, OnInit } from '@angular/core';
import {ScannerService} from '../../../services/scanner.service';
import {RunnerService} from '../../../services/runner.service';
import {distinctUntilChanged, map, mergeMap} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {RunningTeam, RunningTeamStatus} from "../../../models/running-team";
import {GameService} from "../../../services/game.service";
import {RunningTeamMember} from "../../../models/running-team-member";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styles: [
  ]
})
export class RegistrationComponent implements OnInit {

  scanMethod = false;

  searchTerm$ = new BehaviorSubject<string>(''); // Use BehaviorSubject, not Subject!!
  searchResults$: Observable<RunningTeam[]>;

  constructor(public scannerService: ScannerService, public runnerService: RunnerService, public game: GameService) {
    this.searchResults$ = this.searchTerm$.pipe(
      mergeMap(search => this.runnerService.teams$.pipe(
          distinctUntilChanged(),
          map(teams => {
            return teams.filter(team =>
              team.status === RunningTeamStatus.unregistered &&
              (team.name.toLowerCase().includes(search.toLowerCase()) ||
              team.group.toLowerCase().includes(search.toLowerCase()) ||
              team.id.includes(search)));
          })
        )
      )
    );
  }

  updateSearch(search: string): void {
    this.searchTerm$.next(search);
  }

  getOtherNumbers(runner: RunningTeam): Array<RunningTeamMember> {
    return runner.members.filter((x, i) => !!x.phone && i != 0); // Members with phone numbers (not the leader)
  }

  ngOnInit(): void {
  }

}
