<div *ngIf="auth.user && gameService.currentGame else loading">
  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-toasts></app-toasts>

  <footer class="footer">
    <div class="container">
      <div class="d-flex justify-content-between">
        <span class="text-muted">Eccleston Scout Group</span>
        <span class="text-muted">widegame.app</span>
        <span class="text-muted">Created by George Pears</span>
      </div>

    </div>
  </footer>


</div>
<ng-template #loading>Loading...</ng-template>

<div class="position-fixed top-0 end-0 p-3" style="z-index: 1000">
  <ngb-toast
    *ngFor="let toast of toastService.toasts" [class]="toast.bg ? 'text-white bg-' + toast.bg : ''"
    [header]="toast.header" [autohide]="true" [delay]="toast.delay || 5000" class="mb-2"
  >{{toast.body}}</ngb-toast>
</div>
